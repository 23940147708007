.card-container {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    border: 0.5px solid rgb(208, 206, 206);
    border-radius: 12px;
    padding: 8px;

}
.restaurant-photo-container {
    display: flex;
    column-gap: 8px;
    row-gap: 8px;
}
.restaurant-info-container {
    width: 100%;
}
.restaurant-photo {
    height: 180px;
    width: 180px;
    border-radius: 12px;
}
.order-button-container{
    display: flex;
    flex-direction: column;
}
.restaurant-name {
    margin: 0;
    font-weight: 600;
    line-height: 20px;
    font-size: 18px;
    color: var(--main-brown);
}

@media only screen and (min-width: 600px) {
    .card-container {
      max-width: 300px;
    }
  }
