.feed-container {
  min-height: calc(100vh - 186px);
}

.cards-container {
    margin: 0 16px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }

@media only screen and (min-width: 600px) {
  .feed-container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
  .cards-container {
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 24px;
    justify-content: center;
  }
}

@media only screen and (min-width: 992px) {
  .cards-container {
    justify-content: flex-start;
  }
}

@media only screen and (min-width: 1024px) {
  .cards-container {
    justify-content: center;
  }
}