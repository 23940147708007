.footer-container {
    height: 100px;
    margin-top: 26px;
    background-color: var(--main-orange);
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    padding-left: 16px;

}
.faminto-info {
    height: 100%;
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 14px;
    justify-content: center;
    row-gap: 8px;
}
.footer-logo {
    font-size: 14px;
    font-weight: 600;
}
.faminto-info a {
    color: white;
    text-decoration: underline;
}
.social-media {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.social-media img{
    width: 24px;
    padding: 0;
}
.copyright {
    width: 100%;
    font-size: 14px;
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    align-self: center;
    color: white;
}