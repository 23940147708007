.opening-hours-main-container{
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
}
.action-line-container{
    display: flex;
    align-items: center;
}
.arrow-icon{
    width: 18px;
    height: 18px;
    padding-top: 2px;
    padding-left: 2px;
}
.days-hours-container {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    margin-top: 8px;
    max-height: 110px;
    overflow-y: scroll;
}
.open-hour-line {
    display: flex;
    width: 100%;
    column-gap: 12px;
    font-size: 14px;
}
.open-day {
    width: 26px;
}