.category-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    min-width: 72px;
    max-width: 92px;
}
.category-icon {
    height: 42px;
}
.category-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 95%;
}

.icon-container{
    width: 62px;
    height: 62px;
    object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}
.selected-category {
    border-radius: 50%;
    background-color: var(--light-yellow);
    transform: rotate(20deg);
}