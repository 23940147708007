.carousel-container {
    margin: 20px 0;
    padding: 0 0 8px 16px;
    display: flex;
    column-gap: 12px;
    overflow-x: auto;
    -ms-overflow-style: none; 
    scrollbar-width: none; 
}
.carousel-container::-webkit-scrollbar {
    display: none;
}

@media only screen and (min-width: 1024px) {
    .carousel-container {
        justify-content: center;
    }
}