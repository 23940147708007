@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
:root {
  --main-yellow: #EBA327;
  --light-yellow: #f3f3d5;
  --main-orange:#fcb24c ;
  --dark-orange: #EE8C00;
  --main-brown: #7B3911;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: "Lato", sans-serif;
}
