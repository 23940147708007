@import url('https://fonts.googleapis.com/css2?family=Radio+Canada:ital,wght@0,300..700;1,300..700&display=swap');
.topbar-container {
    min-height: 50px;
    background-color: var(--dark-orange);
    color: white;
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-top: 5px;
    top: 0;
    position: sticky;
    z-index: 100;
}
.faminto-logo {
    height: 54px;
}
.faminto-text-logo {
    font-size: 30px;
    font-weight: 600;
    font-family: "Radio Canada", sans-serif;
}